<template>
    <div :class="['d-flex w-100', { 'justify-content-end': noBack }]">
        <st-button
            :callback="goBack"
            variant="light"
            class="mr-auto"
            v-if="!noBack"
        >
            <i class="fas fa-long-arrow-alt-left"></i>{{$t('APPLICATION.FORM.GO_BACK')}}
        </st-button>

        <st-button variant="primary" :callback="previewDocument" v-if="showPreviewBtn">
            <i class="far fa-file"></i>{{$t('APPLICATION.FORM.PREVIEW_DOC')}}
        </st-button>

        <st-button class="ml-4" variant="primary" :callback="takeDataFromConnectedApplications" v-if="showPreviewBtn && record.internal_status!== 'closed' && record.connected_applications?.length">
            <i class="far fa-file"></i>{{$t('APPLICATION.FORM.TAKE_DATA_FROM_CONNECTED_APPLICATIONS')}}
        </st-button>

        <st-button
            variant="light"
            class="ml-4 st-btn-text-primary"
            v-if="showDrafBtn"
            :callback="saveAsDraft"
            :spinner="autoSavePending"
            :disabled="isDraftDisabled || autoSavePending">
            <i class="fas fa-save"></i>{{$t('APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON')}}
        </st-button>

        <st-button variant="primary" :callback="generateDocument" v-if="showGenerateBtn" class="ml-4">
            <i class="far fa-file"></i>{{$t('APPLICATION.FORM.GENERATE_DOCUMENT')}}
        </st-button>
    </div>
</template>

<script>
    import ApplicationDocumentsEditMixin from "@/modules/applications/components/view-application/documents/application-document-edit-mixin";
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: 'ApplicationDocumentsEditToolbar',
        mixins: [ ApplicationDocumentsEditMixin ],
        props: {
            noBack: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            showGenerateBtn() {
                if (!this.generateDocumentActions.length) {
                    return false;
                }

                return !this.isDocumentGenerated
                    && this.applicationCheckStatus.includes(this.record.internal_status)
                    && this.record.assigned_staff_user_id
                    && this.isCurrentUserAssigned;
            },
            showPreviewBtn() {
                return !this.hidePreviewStatus.includes(this.record.internal_status)
                    && this.isCurrentUserAssigned
                    && !this.isDocumentGenerated;
            },
            showDrafBtn() {
                const applicationReviewAction = this.userActions.filter(
                    (el) => el.action_type === this.userActionsTypes.APPLICATION_REVIEW && el.status === this.userActionsStatus.PROCESSING
                );
                return applicationReviewAction || !this.isDocumentGenerated;
            },
        },
        methods: {
            ...mapActions({
                getRelatedDocumentsByIds: "applications/form/getRelatedDocumentsByIds",
            }),
            previewDocument() {
                this.$emit('previewDocument');
            },
            saveAsDraft() {
                const asDraft = this.isDocumentGenerated ? false : true;
                this.$emit('saveAsDraft', asDraft);
            },
            generateDocument() {
                this.$emit('generateDocument');
            },
            goBack() {
               this.$router.go(-1);
            },
            updateRecord(newValue) {
                this.setRecord(newValue);
            },
            async takeDataFromConnectedApplications() {
                this.$alert({
                    type: 'warning',
                    text: this.$t('APPLICATION.NOTIFIERS.TAKE_DATA_FROM_CONNECTED'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: async () => {
                        let metadataCitizenFormShared;
                        let metadataStaffFormShared;
                        if (this.record?.form) {
                            metadataCitizenFormShared = this.record.form.shared_fields;
                        }
                        if (this.record?.form_staff) {
                            metadataStaffFormShared = this.record.form.shared_fields ?? [];
                            if (this.record.form_staff?.shared_fields?.length) {
                                for (const sharedFied of this.record.form_staff.shared_fields) {
                                    if (!metadataStaffFormShared.includes(sharedFied)) {
                                        metadataStaffFormShared.push(sharedFied);
                                    }
                                } 
                            }
                        }
                        if (metadataCitizenFormShared?.length || metadataStaffFormShared?.length) {
                            const recordConnectedApplications = this.record.connected_applications?.map(application => application.application_id);
                            const connectedApplications = await this.getRelatedDocumentsByIds(recordConnectedApplications);
                            // TODO we will need to refactor/implement a new way for this, to allow user to select what to be overwritten, even if 
                            // the metadata is stored against multiple connected applications
                            if (metadataCitizenFormShared?.length) {
                                const recordUpdatedValue = this.updateUniqueFields(this.record, connectedApplications, 'form', metadataCitizenFormShared);
                                this.updateRecord(recordUpdatedValue);
                            }
                            if (metadataStaffFormShared?.length) {
                                const recordUpdatedValue = this.updateUniqueFields(this.record, connectedApplications, 'form_staff', metadataStaffFormShared);
                                this.updateRecord(recordUpdatedValue);
                            }                  
                        }
                    }
                });
            },
            updateUniqueFields(original, connectedApplications, formKey, sharedFields) {
                const fieldCount = new Map();
                const fieldValues = new Map();

                // Count field occurrences and store field values
                connectedApplications.forEach(record => {
                    let sharedMetadata;
                    if (formKey === 'form') {
                        sharedMetadata = record.form.shared_fields ?? [];
                    }
                    if (formKey === 'form_staff') {

                        sharedMetadata = record.form.shared_fields ?? [];  
                        if (record.form_staff.shared_fields?.length) {
                            for (const sharedFied of record.form_staff.shared_fields) {
                                if (!sharedMetadata.includes(sharedFied)) {
                                    sharedMetadata.push(sharedFied);
                                }
                            } 
                        }
                    }       
                    sharedFields.forEach(field => {
                        if (sharedMetadata?.includes(field)) {
                            fieldCount.set(field, (fieldCount.get(field) || 0) + 1);
                            if (!fieldValues.has(field)) {
                                fieldValues.set(field, record[formKey][field]); // Store first occurrence
                                if (record[formKey].options && record[formKey].options[field]) {
                                    const optionsField = field + '_options';
                                    fieldValues.set(optionsField, record[formKey].options[field]); // Store first occurrence
                                }
                            }
                        }
                    });
                });

                // Identify unique fields
                const uniqueFields = [...fieldCount.keys()].filter(field => fieldCount.get(field) === 1);

                // Update original record for unique fields
                uniqueFields.forEach(field => {
                    if (original[formKey].hasOwnProperty(field)) {
                        original[formKey][field] = fieldValues.get(field); // Replace value
                        const optionsField = field + '_options'; 
                        if (fieldValues.get(optionsField)) {
                            original[formKey]['options'] = original[formKey]['options'] ?? {};
                            original[formKey]['options'][field] = fieldValues.get(optionsField);
                        }
                    }
                });

                return original;
            }                     
        }
    };
</script>

<template>
    <st-page
        :duplicateToolbar="true"
        :pageCustomClass="pageCustomClass"
    >
        <Loader v-if="actionsLoading || previewLoading" />
        <template #toolbar>
            <application-documents-edit-toolbar
                key="header-toolbar"
                ref="applicationDocumentsEditToolbar"
                :noBack="noBack"
                @previewDocument="previewDocument"
                @saveAsDraft="saveAsDraft"
                @generateDocument="generateDocument"
                @takeDataFromConnected="takeDataFromConnected"
            ></application-documents-edit-toolbar>
        </template>

        <generate-form
            v-if="formSchema && formStaffModel"
            :data="formSchema"
            :key="formKey"
            :value="formStaffModel"
            ref="generateForm"
            :viewMode="true"
            :partialEdit="partialEdit"
            :humanReview="isHumanReview"
            @child-mounted="generatedFormMounted"
        ></generate-form>
    </st-page>
</template>

<script>
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import { mapGetters, mapActions } from "vuex";
import Message from "@/shared/message/message";
import ApplicationDocumentsEditToolbar from "@/modules/applications/components/view-application/documents/ApplicationDocumentsEditToolbar";
import ApplicationDocumentsEditMixin from "@/modules/applications/components/view-application/documents/application-document-edit-mixin";

export default {
    name: "ApplicationDocumentsEdit",
    mixins: [ ApplicationDocumentsEditMixin ],
    components: {
        GenerateForm,
        ApplicationDocumentsEditToolbar,
    },
    props: {
        noBack: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formSchema: null,
            actionsLoading: false,
            statusProcessingExists: true,
            polling: null,
            autoSave: null,
            previewLoading: false,
            connectedApplications: [],
            formKey:0
        };
    },
    computed: {
        ...mapGetters({
            formStaffModel: "applications/form/formStaffModel",
        }),
        partialEdit() {
            return (this.currentUser.user_type_name === 'staff' || this.currentUser.user_type_name === 'admin') && !this.isDocumentGenerated;
        },
        turnAutoSaveOn() {
            return !this.isDraftDisabled && !this.isDocumentGenerated;
        },
        pageCustomClass() {
            return this.noBack ? 'mt-6' : '';
        },
        loadRejectedForm() {
            return (this.rejectedStatus.includes(this.record.internal_status) || this.record?.is_rejected);
        },
        areUnfinishedConnectedDocuments() {
            return this.connectedApplications?.some((doc) => doc?.status !== 'closed' && doc?.status !== 'rejected');
        },
    },
    methods: {
        ...mapActions({
            doFindForm: "applications/form/findForm",
            doFindApplicationType: "applications/form/findApplicationType",
            doFind: "applications/form/find",
            doUpdate: "applications/form/update",
            doFindUserActions: "applications/form/findUserActions",
            doPreviewDocument: "applications/form/previewDocument",
            doGenerateDocument: "applications/form/generateFinalDocument",
            initApplicationState: "applications/form/initFormState",
            resetUserActions: "applications/form/doResetUserActions",
            getRelatedDocumentsByIds: "applications/form/getRelatedDocumentsByIds",
        }),
        async generateData(asDraft) {
            if (!this.$refs.generateForm) {
                return null;
            }

            return await this.$refs.generateForm.getData(asDraft);
        },
        async saveAsDraft(asDraft, autosave) {
            const genFormData = await this.generateData(asDraft);

            if (!genFormData) {
                return;
            }

            this.autoSavePending = true;

            const formData = {
                form_staff: {
                    ...genFormData.form,
                    options: genFormData.options,
                    shared_fields: genFormData.shared_fields,
                }
            }

            this.doUpdate({
                id: this.record.id,
                values: formData,
                loadStaff: true,
                autosave
            }).catch(() => {
                Message.error('APPLICATION.VIEW.AUTOSAVE_FAILED');
            }).finally(() => {
                this.autoSavePending = false;
            });
        },
        async takeDataFromConnected() {
            if (!this.$refs.generateForm) {
                return null;
            }
            this.formKey += 1;
        },
        async getFormSchema() {
            this.formSchema = null;

            const applicationTypeData = await this.doFindApplicationType(this.record.application_type_id);
            const { form_staff_id, reject_form_staff_id } = applicationTypeData;
            let formStaff = this.loadRejectedForm ? reject_form_staff_id : form_staff_id;
            const formData = await this.doFindForm(formStaff);
            this.formSchema = formData.schema;
        },
        async previewDocument() {
            this.previewLoading = true;

            await this.saveAsDraft(true);

            const { data, downloadAccessToken } = await this.doPreviewDocument({ id: this.record.id});

            this.previewLoading = false;

            window.open(`${window.VUE_APP_API_URL}/files/${data.file_id}?token=${downloadAccessToken}`, '_blank');
        },
        async generateDocument() {
            const action = this.generateDocumentActions.map((el) => el.action_type);
            const lastOccurence = action.lastIndexOf(this.userActionsTypes.DOCUMENT_GENERATION_REQUEST);

            if (lastOccurence !== -1) {
                try {
                    await this.saveAsDraft(false);
                    const recordConnectedApplications = this.record?.connected_applications?.map(el => el.application_id);
                    if (recordConnectedApplications?.length) {
                        this.connectedApplications = await this.getRelatedDocumentsByIds(recordConnectedApplications);
                        if (this.connectedApplications?.length && this.areUnfinishedConnectedDocuments) {
                            this.$alert({
                                type: 'warning',
                                text: this.$t('APPLICATION_REGISTER.NOTIFIERS.UNFINISHED_CONNECTED_DOCUMENTS_TEXT'),
                                confirmButtonText: this.$t('GENERAL.YES'),
                                cancelButtonText: this.$t('GENERAL.NO'),
                                hasConfirmation: true,
                                confirmCallback: async () => {
                                    await this.doGenerateDocument({ id: this.record.id, action: this.generateDocumentActions[lastOccurence].id});
                                    clearInterval(this.autoSave);
                                    this.autoSave = null;

                                    this.polling = setInterval(async () => {
                                        await this.processUserActions();
                                    }, 1000);

                                    Message.success('APPLICATION.VIEW.GENERATE_DOCUMENT_MESSAGE');
                                },
                            });

                            return false;
                        }
                    } 
                    await this.doGenerateDocument({ id: this.record.id, action: this.generateDocumentActions[lastOccurence].id});
                    clearInterval(this.autoSave);
                    this.autoSave = null;

                    this.polling = setInterval(async () => {
                        await this.processUserActions();
                    }, 1000);

                    Message.success('APPLICATION.VIEW.GENERATE_DOCUMENT_MESSAGE');
                } catch (error) {
                    if (error === 'VALIDATION_ERROR') {
                        this.$alert({
                            type: 'warning',
                            text: this.$t('FORMS.MESSAGE.VALID_ERROR'),
                            showConfirmButton: false,
                        });
                    } else {
                        Message.error('APPLICATION.VIEW.GENERATE_DOCUMENT_ERROR');
                    }
                }
            } else {
                Message.error('APPLICATION.VIEW.GENERATE_DOCUMENT_ERROR');
            }
        },
        async processUserActions() {
            try {
                await this.getUserActions();

                this.actionsLoading = true;

                if (this.statusProcessingExists.length !== 0) {
                    this.actionsLoading = false;
                    clearInterval(this.polling);

                    //get updated application
                    await this.doFind({
                        id: this.$route.params.id,
                        loadStaff: true,
                    });
                }
            } catch (error) {
                clearInterval(this.polling);
                Message.error(error);
            }
        },
        async getUserActions() {
            const paramsResolved = {
                query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.RESOLVED}`,
                sort: '-created_date'
            };
            const paramsProcessing = {
                query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.PROCESSING}`,
                sort: '-created_date'
            };

            await this.doFindUserActions(paramsResolved);
            this.statusProcessingExists = await this.doFindUserActions(paramsProcessing);
        },
        generatedFormMounted() {
            this.$emit('form-mounted');
            this.$emit('map-mounted');
        }
    },
    async mounted() {
        if (this.turnAutoSaveOn) {
            this.autoSave = setInterval(async () => {
                await this.saveAsDraft(true, true);
            }, 30000); // Auto-save every 30s
        }
    },
    async created() {
        if (this.$route.params.id && !this.record) {
            await this.doFind({
                id: this.$route.params.id,
                loadStaff: true,
            });
        }

        //find corresponding form schema
        await this.getFormSchema();

        //find user actions related to application
        this.resetUserActions();
        await this.getUserActions();

        //pre save draft
        if (!this.record.form_staff || this.record?.connected_applications?.length) {
            await this.saveAsDraft(true);
        }
    },
    watch: {
        'record.internal_status': {
            deep: true,
            async handler(value, oldValue) {
                if (value !== oldValue) {
                    if (value === 'approved' || this.rejectedStatus.includes(value)) {
                        await this.getUserActions();
                        await this.getFormSchema();
                    }

                    if (this.loadRejectedForm) {
                        if (this.autoSave) {
                            clearInterval(this.autoSave);
                        }
                    }
                }
            }
        },
        'record.assigned_staff_user_id': {
            deep: true,
            async handler(value, oldValue) {
                if (value !== oldValue && this.turnAutoSaveOn) {
                    if (this.autoSave) {
                        clearInterval(this.autoSave);
                    }

                    this.autoSave = setInterval(async () => {
                        await this.saveAsDraft(true);
                    }, 30000); // Auto-save every 30s
                }
            }
        },
        'record.application_returned': {
            deep: true,
            async handler(value, oldValue) {
                if (value !== oldValue) {
                    await this.getFormSchema();
                }
            }
        }
    },
    beforeDestroy () {
        if (this.polling) {
            clearInterval(this.polling);
        }
        if (this.autoSave) {
            clearInterval(this.autoSave);
        }
    },
};
</script>
